import React from 'react'
import { List, BooleanField, Datagrid, TextField, ReferenceField,DateField, EditButton,
  DeleteButton, Edit, SimpleForm,ReferenceInput,SelectArrayInput,ReferenceManyField,ChipField,SingleFieldList,
  ReferenceArrayInput, SelectInput,required, NumberInput,
  TextInput, DateInput, BooleanInput, Create  } from 'react-admin'
import { useRecordContext, Show, SimpleShowLayout } from 'react-admin';


export const DIDList = () => {
  return <List title="List of DIDS">
    <Datagrid >
      <TextField source="id"/>
      {/*<TextField source="systemId"/>*/}
      <ReferenceField label="System" source="systemId"  reference="systems" >
        <TextField source="systemName"  emptyText="missing data"  />
      </ReferenceField>
      {/*<ReferenceField label="System" source="systemId" reference="mxsystems">*/}
        {/*<TextField source="systemName" />*/}
      {/*</ReferenceField>*/}
      <ReferenceField label="Provider" source="providerId" reference="providers" >
        <TextField source="hostName" />
      </ReferenceField>
      {/*<ReferenceManyField label="Comments by" reference="providers" target="providerId">*/}
        {/*<SingleFieldList>*/}
          {/*<ChipField source="hostName" />*/}
        {/*</SingleFieldList>*/}
      {/*</ReferenceManyField>*/}
      {/*<TextField source="providerId"/>*/}
      <TextField source="did" label="DID" />
      <BooleanField source="isTemporary"  label="Is Temporary" />
      <EditButton />
      <DeleteButton/>
    </Datagrid>
  </List>
}

export const DIDEdit= () => {
  return (
    <Edit title='Edit a DID'>
      <SimpleForm>
        <TextInput disabled source='id'/>
        <ReferenceInput label="System"  perPage={100} source="systemId"  reference="systems"   >
          <SelectInput  optionValue="id" emptyValue={0} optionText="systemName" />
        </ReferenceInput>
        <ReferenceInput  label="Provider" source="providerId" reference="providers"  allowEmpty>
          <SelectInput emptyValue={0} optionText="hostName" optionValue="id"/>
        </ReferenceInput>
        {/*<NumberInput source="providerId"/>*/}
        {/*<SelectInput source="providerId" reference="SMPP"  />*/}
        <TextInput source="did"  label="DID" />
        <BooleanInput source="isTemporary"  label="Is Temporary" />
      </SimpleForm>
    </Edit>
  )
}

export const DIDCreate= () => {
  return (
    <Create title='Create a DID'>
      <SimpleForm>
        <ReferenceInput label="System"  perPage={100} source="systemId"  reference="systems"   >
          <SelectInput  optionValue="id" emptyValue={0} optionText="systemName" />
        </ReferenceInput>
        <ReferenceInput label="Provider" source="providerId" reference="providers">
          <SelectInput  optionText="hostName" optionValue="id"/>
        </ReferenceInput>
        <TextInput source="did"  label="DID" />
        <BooleanInput source="isTemporary"  label="Is Temporary" />
      </SimpleForm>
    </Create>
  )
}

