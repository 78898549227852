import React from 'react'
import { List, Datagrid, TextField, EmailField,
  EditButton, DeleteButton,BooleanField,
  Edit, TextInput, BooleanInput,PasswordInput,
  Create, SimpleForm, DateInput, NumberInput
} from 'react-admin'

export const ProviderList = () => {
  return <List>
    <Datagrid>
      <TextField disabled source="id"/>
      <TextField source="description"/>
      <TextField source="hostName"/>
      <BooleanField source="isSecure"/>
      <TextField source="port"/>
      <TextField source="userName" label="User Name"/>
      <EditButton />
      <DeleteButton/>
    </Datagrid>
  </List>
}

export const ProviderEdit= () => {
  return (
    <Edit title='Edit SMPP Provider' >
      <SimpleForm>
        <TextInput disabled source="id"/>
        <TextInput source="hostName" label="Host Name"/>
        <BooleanInput source="isSecure"/>
        <NumberInput source="port" label="Port"/>
        <TextInput source="userName" label="User Name"/>
        <PasswordInput source="userPassword" />
      </SimpleForm>
    </Edit>
  )
}

export const ProviderCreate= () => {
  return (
    <Create title='Create Provider'>
      <SimpleForm>
        <TextInput source="description"/>
        <TextInput source="hostName"/>
        <BooleanInput source="isSecure"/>
        <NumberInput source="port"/>
        <TextInput source="userName" label="User Name"/>
        <TextInput source="userPassword" label="User Password"/>
      </SimpleForm>
    </Create>
  )
}
