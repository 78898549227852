import * as React from "react";
import  { List,
  Datagrid,
  TextField,
  ReferenceField,
  EditButton,
  Edit,
  SimpleForm,
  ReferenceInput,
  SelectInput,DeleteButton,Toolbar,
  SaveButton,
  TextInput,Create,useNotify } from 'react-admin';
import { PasswordInput } from 'react-admin';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useRecordContext, Show, SimpleShowLayout } from 'react-admin';
import smsBaseProvider from "../../smsBaseProvider";

import { useFormContext } from 'react-hook-form';

const MyDeleteButton = () => {
  const record = useRecordContext();
  console.log(record)
  if (record.protected) {
    // Note, no label, just shows an icon
    return <DeleteButton label='Delete' className="protected-class"/>
  } else {
    return <DeleteButton label='Delete'/>
    // return null
  }
}




export const UserList = () => (

  <List>
    <Datagrid rowClick="edit">
      <TextField disabled source="id"/>
      <TextField source="description"/>
      <TextField source="firstName"/>
      <TextField source="lastName"/>
      <TextField source="login"/>
      <EditButton />
      <MyDeleteButton></MyDeleteButton>
      {/*<DeleteButton></DeleteButton>*/}
      {/*<DeleteButton/>*/}
    </Datagrid>
  </List>
);

const CustomToolbar = props => {
  const record = useRecordContext();
  console.log(record)
  if(record.protected){

    return (
      <Toolbar
        {...props}
        sx={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <SaveButton />
        <DeleteButton className='protected-class' />
      </Toolbar>
    );
  } else {
    return (
      <Toolbar
        {...props}
        sx={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <SaveButton />
        <DeleteButton />
      </Toolbar>
      )
  }
}

const SetSecret = () => {
  const {getValues, setValue } = useFormContext();
  const record = useRecordContext();
  const notify = useNotify();

  const RemoveTaskFunction = (arg,e) => {
    e.preventDefault()

    console.log(getValues('password'));
    console.log(getValues('newpassword'));
    smsBaseProvider.changePassword(getValues('password'),getValues('newpassword'),record.id).then(function (data) {
      console.log(data)
      notify(`Password updated`);
    }).then(json => {
      console.log(json)
      // handle response normally here
    }).catch(function (error) {
      console.log(error)
      notify(`${error}`);
    })
    // smsBaseProvider.generateSecret().then(function (data) {
    //   // secret = data.data.data.secret;
    //   // // console.log(secret)
    //   // setValue('secret',secret)
    // })
  };
  return (
    <Button variant="contained" sx={{ height: 48}} onClick={function(e){RemoveTaskFunction('secret', e)}} size="medium">Change password</Button>
  )
}

export const UserEdit = () => (
  <Edit title='Edit a User' >
    <SimpleForm toolbar={<CustomToolbar />}>
      <TextInput disabled source='id'/>
      <TextInput source="description"/>
      <TextInput source="firstName"/>
      <TextInput source="lastName"/>
      <PasswordInput source="password" className = 'password-sms'/>
      <Stack spacing={3} direction="row" >
        <PasswordInput source="newpassword" label = "Repeat password" className = 'password-sms'/>
        <Box  style={{marginTop: '10px'}}>
          <SetSecret></SetSecret>
        </Box>
      </Stack>
      {/*<PasswordInput source="passwordNew" inputProps={{ autocomplete: 'current-password' }} />*/}
      {/*<TextInput source="password"/>*/}
      <TextInput source="login"/>
      </SimpleForm>
  </Edit>
);

export const UserCreate= () => {
  console.log('user creations')
  return (
    <Create title='Create a User'>
      <SimpleForm>
        <TextInput source="firstName"/>
        <TextInput source="lastName"/>
        <TextInput source="login"/>
        <TextInput source="password"/>
        <TextInput source="description"/>
      </SimpleForm>
    </Create>
  )
}
