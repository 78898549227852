import React from 'react'
import smsBaseProvider from '../../smsBaseProvider'
import { List, Datagrid, EmailField,
  EditButton, BooleanField, DeleteButton,useRecordContext, Edit,useNotify, useRedirect,
  SimpleForm, TextInput, DateInput,Toolbar, SaveButton,
  BooleanInput ,NumberInput,SearchInput,
  Create,ReferenceInput,SelectInput,TextField, FormDataConsumer,CreateBase, ReferenceField  } from 'react-admin';
import { useWatch } from 'react-hook-form';
// import TestInput from './token'
import { useState, useEffect } from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
//import { useController, useForm } from "react-hook-form";
//import jsonServerProvider from "ra-data-json-server/dist/index";
// import TextField from '@mui/material/TextField';
import { useFormContext } from 'react-hook-form';

import { useDataProvider, Loading, Error } from 'react-admin';

const postFilters = [
  <SearchInput source="serial" alwaysOn placeholder='Search by serial' />
];

export const MxSystemList = () => {
  return <List title='Mx System List' filters={postFilters}>
    <Datagrid >
      <ReferenceField label="Provider" source="providerId" reference="providers" >
        <TextField source="hostName" />
      </ReferenceField>
      <TextField source="systemName"/>
      {/*<TextField source="accessToken"/>*/}
      {/*<TextField source="secret"/>*/}
      <TextField source="status"/>
      <TextField source="description"/>
      <TextField source="serial"/>
      {/*<TextField source="pwd" label="Password"/>*/}
      <EditButton />
      <DeleteButton></DeleteButton>
      {/*<DeleteButton/>*/}
    </Datagrid>
  </List>
}

const SetSecret = () => {
 const { setValue } = useFormContext();
  const RemoveTaskFunction = (arg,e) => {
    e.preventDefault()
    smsBaseProvider.generateSecret().then(function (data) {
      secret = data.data.data.secret;
      // console.log(secret)
     setValue('secret',secret)
    })
  };
  return (
    <Button variant="contained" sx={{ height: 48}} onClick={function(e){RemoveTaskFunction('secret', e)}} size="medium">Re-generate secret</Button>
  )
}
const PostEditToolbar = () => (
  <Toolbar>
    <SaveButton alwaysEnable sx={{ margin: 2 }}/>
    <DeleteButton />
  </Toolbar>
);
const SetToken = () => {
  const { setValue } = useFormContext();
  const RemoveTaskFunction = (arg,e) => {
    e.preventDefault()
    smsBaseProvider.generateToken().then(function (data) {
      accessToken = data.data.data.accessToken;
      setValue('accessToken',accessToken)
    })
  };
  return (
    <Button variant="contained" sx={{ height: 48}} onClick={function(e){RemoveTaskFunction('secret', e)}} size="medium">Re-generate token</Button>
  )
}


const HandleClick = (event, value) => {

  // value is a `Date` object
  console.log(event, value)
  const { setValue } = useFormContext();

  console.log(event, value)
  const record = useRecordContext()
  console.log(record)
  if(record.status==='enabled'){
    setValue('status',true)
  } else{
    setValue('false',false)
  }
  return (
    <div><BooleanInput source="status" onChange={handleEvents(value)}/></div>
  )
};

const handleEvents = (e)=>{
  console.log(e)
}


const FormattedBooleanInput = ({
                                 format,
                                 parse,
                                 source,
                                 options,
                                 ...props
                               }) => (
  <FormDataConsumer>
    {({ formData }) => (
      <BooleanInput
        options={{
          checked: format(formData[source]),
          ...options,
        }}
        format={format}
        parse={parse}
        source={source}
        {...props}
      />
    )}
  </FormDataConsumer>
);

FormattedBooleanInput.defaultProps = {
  options: {},
};

export const MxSystemEdit= () => {
  const dateFormatter = value => {
    // value is a `Date` object
    if(value==='enabled'){
      console.log(value)
      return true
    } else {
      return false
    }

  };

  const dateParser = value => {

    // value is a `Date` object
    console.log(value)
    if(!value){
      console.log(value)
      return true
    } else {
      return false
    }
  };


  return (
    <Edit title='Edit a MxSystem' >
      <SimpleForm toolbar={<PostEditToolbar />} >
        <ReferenceInput label="Provider" source="providerId" reference="providers">
          <SelectInput  optionText="hostName" emptyValue={0} optionValue="id"/>
        </ReferenceInput>
        <TextInput source="systemName" label="System Name"/>
        {/*<BooleanInput source="status" />*/}
        <FormattedBooleanInput
          format={(value) => value === "enabled"}
          parse={(value) => (value ? "enabled" : "disabled")}
          source="status"
        />
        <TextInput source="description"  label="Description"/>
        <TextInput source="serial"  label="Serial"/>
        <Stack spacing={3} direction="row" >
        <TextInput source="secret" value={secret}  label="Secret"/>
        <Box  style={{marginTop: '10px'}}>
          <SetSecret></SetSecret>
        </Box>
      </Stack>
        <Stack spacing={3} direction="row" >
          <TextInput source="accessToken" value={accessToken}  label="Access token"/>
          <Box  style={{marginTop: '10px'}}>
            <SetToken></SetToken>
          </Box>
        </Stack>
        {/*<Stack spacing={3} direction="row">*/}
          {/*<TextInput source="accessToken" disabled label="Access Token"/>*/}
          {/*<Button variant="contained">Contained</Button>*/}
        {/*</Stack>*/}
      </SimpleForm>
    </Edit>
  )
}


// const TestInput = () => {
//   smsBaseProvider.generateSecret().then(function (data) {
//     secret = useController(data.data.data.secret);
//     console.log(secret)
//   })
//   smsBaseProvider.generateToken().then(function (data) {
//     accessToken = useController(data.data.data.accessToken);
//     console.log(accessToken)
//   })
//
// }


// const Input = ({ control }: { control: Control<FormValues> }) => {
//   const { field } = useController({
//     control,
//     name: "test"
//   });
//   const [value, setValue] = React.useState(String(field.value));
//
//   return (
//     <input
//       ref={field.ref}
//       value={value}
//       onChange={(e) => {
//         field.onChange(parseInt(e.target.value, 10)); // send data to hook form
//         setValue(e.target.value);
//       }}
//       onBlur={field.onBlur}
//     />
//   );
// };



//   const CreateRelatedCommentButton = () => {
//    // const {register, setValue} = useController()
//    //  console.log(register)
//     smsBaseProvider.generateToken().then(function (data) {
//       accessToken = data.data.data.accessToken;
//       // setValue('accessToken',accessToken)
//     })
//
//       smsBaseProvider.generateSecret().then(function (data) {
//         secret = data.data.data.secret;
//         // console.log(secret)
//         // setValue('secret',secret)
//       })
//
//     // return (
//     //   <span></span>
//     // )
//   //
//  }
// //
//  CreateRelatedCommentButton()
// //

// const ClearCountry = () => {
//
//    const { setValue } = useFormContext();
//     console.log(setValue)
//
//
//       setValue('accessToken', 'fsaf');
//
//
//   return <span>test</span>
// }


// let response = await fetch(`https://10.10.40.79/sms/generate_access_token`)
// console.log(response)
  // let input = document.querySelector('#accessToken');
  // console.dir(input)
  // input.setAttribute('value','My default value')
  // let input = label.querySelector('input')

  // console.dir(label)
// }
//
// })

let accessToken;
let secret;

//const dataProvider = jsonServerProvider('https://jsonplaceholder.typicode.com');


export const MxSystemCreate= () => {
//   console.log(accessToken)
//  // const postDefaultValue = () => ({ id: '1', created_at: new Date(), nb_views: 0 });
// console.log(isLoading)
//   // CreateRelatedCommentButton()
//

  // smsBaseProvider.generateToken().then(function (data) {
  //   accessToken = data.data.data.accessToken;
  //   // setValue('accessToken',accessToken)
  // })
  //
  // smsBaseProvider.generateSecret().then(function (data) {
  //   secret = data.data.data.secret;
  //   // console.log(secret)
  //   // setValue('secret',secret)
  // })

  // if (isloading) return null;

  const notify = useNotify();
  const redirect = useRedirect();
  const dataProvider = useDataProvider();
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const onSuccess = (data) => {
    notify(`Mx System created`);
    redirect(`/systems`);
  };
  useEffect(() => {
    // if(!loading) {
    //   return
    // }
    console.log(loading)
    smsBaseProvider.generateToken().then(function (data) {
      accessToken = data.data.data.accessToken;
      // setValue('accessToken',accessToken)
    }).then(function (data) {
      smsBaseProvider.generateSecret().then(function (data) {
        secret = data.data.data.secret;
        setUser(data);
        setLoading(false);
        // console.log(secret)
        // setValue('secret',secret)
      })
    })



    // dataProvider.getOne('mxsystems', { id: 11 })
    //   .then(({ data }) => {
    //     console.log(data)
    //     setUser(data);
    //     setLoading(false);
    //     console.log(loading)
    //   })
    //   .catch(error => {
    //     setError(error);
    //     setLoading(false);
    //   })
  }, []);

  if (loading) return <Loading />;
  if (error) return <Error />;
  if (!user) return null;
 console.log(loading)
  console.log('rendered')
  // return (
  //   <ul>
  //     <li>Name: {user.name}</li>
  //     <li>Email: {user.email}</li>
  //   </ul>
  // )

  // console.log(accessToken,secret)
  return (
    <CreateBase title='Create MX System' mutationOptions={{ onSuccess }}>
      <SimpleForm >
        <ReferenceInput label="Provider" source="providerId" reference="providers">
          <SelectInput  optionText="hostName" optionValue="id"/>
        </ReferenceInput>
        <TextInput required source="systemName" label="System Name"/>
        <BooleanInput source="status"/>
        {/*<TextInput source="id" />*/}
        {/*<TextInput source="created_at" />*/}
        {/*<TextInput source="nb_views" />*/}
        {/*<TextInput record={{}}/>*/}
        {/*<ZipLookUp></ZipLookUp>*/}
        <TextInput source="description"  label="Description"/>
        <TextInput source="serial"  label="Serial"/>
        <TextInput source="accessToken" disabled defaultValue = {accessToken} value={accessToken} label="accessToken"/>
        <TextInput source="secret" value={secret}  disabled defaultValue = {secret}  label="Secret"/>


      </SimpleForm>
    </CreateBase>
  )

}

